import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchOutlines } from '@/api/outlines';

import { OUTLINES_FILTERS } from '@/utils/constants';

const {
  SORTBY: { RATING, CREATEDAT, COUNTRATING },
} = OUTLINES_FILTERS;

export const getFirstyearOutlines = createAsyncThunk(
  'recommendedOutlines/getFirstyearOutlines',
  async () => {
    const response = await fetchOutlines({
      sortby: COUNTRATING,
      firstYear: true,
    });

    return response.data.outlines;
  },
);

export const getTopRatedOutlines = createAsyncThunk(
  'recommendedOutlines/getTopRatedOutlines',
  async () => {
    const response = await fetchOutlines({ sortby: RATING });

    return response.data.outlines;
  },
);

export const getRecentUplaodedOutlines = createAsyncThunk(
  'recommendedOutlines/getRecentUplaodedOutlines',
  async () => {
    const response = await fetchOutlines({ sortby: CREATEDAT });

    return response.data.outlines;
  },
);

export const getCustomRecommendedOutlines = createAsyncThunk(
  'recommendedOutlines/getCustomRecommendedOutlines',
  async ({ courseId, professorId, outlineId }) => {
    const {
      data: { outlines: courseOutlines },
    } = await fetchOutlines({ courseId });
    const {
      data: { outlines: professorOutlines },
    } = await fetchOutlines({ professorId });

    return { courseOutlines, professorOutlines, outlineId };
  },
);
