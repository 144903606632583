import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchByName as fetchByNameAPI } from '../../api/search';

export const fetchByName = createAsyncThunk(
  'search/searchByName',
  async ({ name, limit }) => {
    const response = await fetchByNameAPI(name, limit);

    return response.data;
  },
);
