import Client from '@/lib/axios';

const axios = Client();

export const fetchProfessors = filters =>
  axios.get('/professors/all', { params: filters });

export const fetchProfessor = id => axios.get(`/professors/${id}`);

export const createProfessor = ({ name }) =>
  axios.post(`/professors`, { name });

export const updateProfessor = ({ professorId, name }) =>
  axios.put(`/professors/${professorId}`, { name });

export const deleteProfessor = professorId =>
  axios.delete(`/professors/${professorId}`);
