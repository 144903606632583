import { createSlice } from '@reduxjs/toolkit';
import { getBalance } from './asyncAction';

const initialState = {
  history: null,
  balance: 0.0,
  isLoading: false,
  errorMessage: '',
};

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    clearBalance(state) {
      state.history = null;
      state.balance = 0.0;
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [getBalance.pending]: state => {
      state.history = null;
      state.isLoading = true;
      state.errorMessage = '';
    },
    [getBalance.fulfilled]: (state, { payload }) => {
      const { balance, buyersOutlines, pageData } = payload;

      state.history = { buyersOutlines, pageData };
      state.balance = balance;
      state.isLoading = false;
    },
    [getBalance.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.errorMessage = error.message;
    },
  },
});

export const { clearBalance } = balanceSlice.actions;

export default balanceSlice.reducer;
