import { createAsyncThunk } from '@reduxjs/toolkit';
import { signOut } from 'next-auth/client';

import { fetchUserData, createUser } from '@/api/user';
import { allUsersManagement } from '@/api/admin';

export const getAllUsersManagement = createAsyncThunk(
  'users/getAllUsersManagement',
  async (filters = null) => {
    const response = await allUsersManagement(filters);

    return response.data;
  },
);

export const fetchUser = createAsyncThunk(
  'user/getUser',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchUserData(id);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const createNewUser = createAsyncThunk(
  'user/createUser',
  async ({ id, name, email }, { dispatch }) => {
    try {
      await createUser({ auth_id: id, name, email });
      dispatch(fetchUser(id));
    } catch (err) {
      signOut();
    }
  },
);
