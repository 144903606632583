import Client from '@/lib/axios';

const axios = Client();

export const fetchProfessorsSuggestions = filters =>
  axios.get('/professorsSuggestions/all-suggestions', { params: filters });

export const createProfessorSuggestion = ({ name, user_id }) =>
  axios.post(`/professorsSuggestions`, { name, user_id });

export const approveProfessorSuggestion = professorSuggestionId =>
  axios.post(`/professorsSuggestions/approve/${professorSuggestionId}`);

export const deleteProfessorSuggestion = professorSuggestionId =>
  axios.delete(`/professorsSuggestions/${professorSuggestionId}`);
