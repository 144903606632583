import { createSlice } from '@reduxjs/toolkit';
import { getSchools, getSingleSchool } from './asyncAction';

const initialState = {
  schools: [],
  schoolsTotalCount: 0,
  isLoading: false,
  errorMessage: '',
  schoolSelected: null,
};

const schoolSelectedSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    clearSchools() {
      return initialState;
    },
  },
  extraReducers: {
    // Get schools
    [getSchools.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getSchools.fulfilled]: (state, { payload }) => {
      state.schools = payload.schools;
      state.schoolsTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getSchools.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get single school
    [getSingleSchool.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getSingleSchool.fulfilled]: (state, { payload }) => {
      state.schoolSelected = payload;
      state.isLoading = false;
    },
    [getSingleSchool.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export const { clearSchools } = schoolSelectedSlice.actions;

export default schoolSelectedSlice.reducer;
