import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCoursesSuggestions } from '@/api/coursesSuggestions';

export const getCoursesSuggestions = createAsyncThunk(
  'coursesSuggestions/getCoursesSuggestions',
  async (filters = null) => {
    const response = await fetchCoursesSuggestions(filters);

    return response.data;
  },
);
