import Client from '@/lib/axios';

const axios = Client();

export const fetchCourses = filters =>
  axios.get('/courses/all', { params: filters });

export const fetchCourse = id => axios.get(`/courses/${id}`);

export const createCourse = ({ name, schoolYear }) =>
  axios.post(`/courses`, { name, schoolYear });

export const updateCourse = ({ courseId, name, schoolYear }) =>
  axios.put(`/courses/${courseId}`, { name, schoolYear });

export const deleteCourse = courseId => axios.delete(`/courses/${courseId}`);
