import Client from '@/lib/axios';

const axios = Client();

export const fetchUserData = authId =>
  axios.get(`/users/getByAuth0Id/${authId}`);

export const createUser = user => axios.post(`/users`, { user });

export const fetchUserPaymentMethods = userId =>
  axios.get(`/users/${userId}/payment_methods`, { params: { type: 'card' } });

export const attachPaymentMethodToCustomer = ({
  userId,
  paymentMethod,
  defaultPaymentMethod,
}) =>
  axios.post(`/users/${userId}/payment_methods/${paymentMethod}/attach`, {
    default: defaultPaymentMethod,
  });

export const createPaymentIntent = ({ userId, outlineId, paymentMethodId }) =>
  axios.post(`/users/${userId}/payment_intents`, {
    outlineId,
    paymentMethodId,
  });

export const createBankAccount = ({ userId, token }) =>
  axios.post(`/users/${userId}/bank_account`, {
    userId,
    externalAccount: token,
  });

export const updateDefaultBankAccount = ({ userId, bankAccountId }) =>
  axios.post(`/users/${userId}/default_bank_account`, {
    userId,
    bankAccountId,
  });

export const removeBankAccount = ({ userId, bankAccountId }) =>
  axios.delete(`/users/${userId}/bank_account/${bankAccountId}`, {
    userId,
    bankAccountId,
  });

export const fetchBankAccount = ({ userId, limit }) =>
  axios.get(`/users/${userId}/bank_account`, {
    userId,
    limit,
  });

export const fetchBalance = ({ userId, type, page, pageSize }) =>
  axios.get(`/users/${userId}/balance`, { params: { type, page, pageSize } });

export const fetchPurchasedOutlines = ({ userId, type, page, pageSize }) =>
  axios.get(`/users/${userId}/outlines`, { params: { type, page, pageSize } });

export const fetchUploadedOutlines = ({ userId, page, pageSize }) =>
  axios.get(`/users/${userId}/uploaded_outlines`, {
    params: { page, pageSize },
  });

export const detachPaymentMethodFromCustomer = ({ userId, paymentMethodId }) =>
  axios.post(`/users/${userId}/payment_methods/${paymentMethodId}/detach`, {});

export const createFinancialConnectionSession = userId =>
  axios.post(`/users/financial_connection_session/${userId}`, {});
