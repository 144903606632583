import { createSlice } from '@reduxjs/toolkit';
import {
  getOutlines,
  getSingleOutline,
  downloadOutline,
  createReview,
  getReviews,
  getReviewsDetails,
} from './asyncAction';

const initialState = {
  outlines: [],
  outlinesTotalCount: 0,
  isLoading: false,
  errorMessage: '',
  outlineSelected: null,
  // download outline
  isLoadingDownload: false,
  downloadError: '',
  downloaded: false,
  refreshTab: false,
  // Create a review
  isLoadingReview: false,
  reviewError: '',
  outlineReview: null,
  reviews: null,
  reviewsDetails: null,
};

const outlineSelectedlice = createSlice({
  name: 'outlines',
  initialState,
  reducers: {
    clearDownload(state) {
      state.downloaded = false;
      state.downloadError = '';
    },
    tabDownloadOutline(state, { payload }) {
      state.refreshTab = payload;
    },
    clearReview(state) {
      state.outlineReview = null;
      state.reviewError = '';
      state.reviews = null;
      state.reviewsDetails = null;
    },
  },
  extraReducers: {
    // Get outlines
    [getOutlines.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getOutlines.fulfilled]: (state, { payload }) => {
      state.outlines = payload.outlines;
      state.outlinesTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getOutlines.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get single outline
    [getSingleOutline.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getSingleOutline.fulfilled]: (state, { payload }) => {
      state.outlineSelected = payload;
      state.isLoading = false;
    },
    [getSingleOutline.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Download outline
    [downloadOutline.pending]: state => {
      state.isLoadingDownload = true;
      state.downloadError = '';
      state.downloaded = false;
    },
    [downloadOutline.fulfilled]: state => {
      state.downloaded = true;
      state.isLoadingDownload = false;
    },
    [downloadOutline.rejected]: (state, { error: { message } }) => {
      state.isLoadingDownload = false;
      state.downloadError = message;
    },
    // Create outline review
    [createReview.pending]: state => {
      state.reviewError = '';
      state.isLoadingReview = true;
    },
    [createReview.fulfilled]: (state, { payload }) => {
      state.outlineReview = payload;
      state.isLoadingReview = false;
    },
    [createReview.rejected]: (state, { error: { message } }) => {
      state.reviewError = message;
      state.isLoadingReview = false;
    },
    // Get outline reviews
    [getReviews.pending]: state => {
      state.reviewError = '';
      state.isLoadingReview = true;
    },
    [getReviews.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
      state.isLoadingReview = false;
    },
    [getReviews.rejected]: (state, { error: { message } }) => {
      state.reviewError = message;
      state.isLoadingReview = false;
    },
    // Get outline reviews details
    [getReviewsDetails.pending]: state => {
      state.reviewError = '';
      state.isLoadingReview = true;
    },
    [getReviewsDetails.fulfilled]: (state, { payload }) => {
      state.reviewsDetails = payload;
      state.isLoadingReview = false;
    },
    [getReviewsDetails.rejected]: (state, { error: { message } }) => {
      state.reviewError = message;
      state.isLoadingReview = false;
    },
  },
});

export const { clearDownload, clearReview, tabDownloadOutline } =
  outlineSelectedlice.actions;

export default outlineSelectedlice.reducer;
