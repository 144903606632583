import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchOutlines,
  fetchOutline,
  downloadOutline as downloadOutlineAPI,
  createReview as createReviewAPI,
  fetchReviews,
  fetchReviewsDetails,
} from '@/api/outlines';

export const getOutlines = createAsyncThunk(
  'outlines/getOutlines',
  async (filters = null) => {
    const response = await fetchOutlines(filters);

    return response.data;
  },
);

export const getSingleOutline = createAsyncThunk(
  'outline/getOutline',
  async id => {
    const response = await fetchOutline(id);

    return response.data;
  },
);

export const downloadOutline = createAsyncThunk(
  'outline/downloadOutline',
  async ({ userId, outlineId, purchaseId }) =>
    await downloadOutlineAPI({ userId, outlineId, purchaseId }),
);

export const createReview = createAsyncThunk(
  'outline/createReview',
  async ({ outlineId, userId, rating, title, content }) => {
    const response = await createReviewAPI({
      outlineId,
      userId,
      rating,
      title,
      content,
    });

    return response.data;
  },
);

export const getReviews = createAsyncThunk(
  'outline/getReviews',
  async ({ outlineId, page, pageSize = 10 }) => {
    const response = await fetchReviews({ outlineId, page, pageSize });

    return response.data;
  },
);

export const getReviewsDetails = createAsyncThunk(
  'outline/getReviewsDetails',
  async outlineId => {
    const response = await fetchReviewsDetails(outlineId);

    return response.data;
  },
);
