// Balance
export const BALANCE = {
  ALL: 'all',
  INCOME: 'income',
  OUTCOME: 'outcome',
};
// Purchased Outlines
export const PURCHASED = {
  AVAILABLE: 'available',
  DOWNLOADS: 'downloads',
  DOWNLOADED: 'downloaded',
  DEPRECATED: 'deprecated',
};
// Payment method
export const PAYMENT_VIEW = {
  SELECT_PAYMENT_METHOD: 'selectPaymentMethod',
  ADD_PAYMENT_METHOD: 'addPaymentMethod',
  ADD_BANK: 'addBank',
};

export const OUTLINES_FILTERS = {
  SORTBY: {
    RATING: 'average_rating',
    CREATEDAT: 'created_at',
    SCHOOLYEAR: 'school_year',
    COURSENAME: 'course.name',
    COURSENAME_ASC: 'course.name:asc',
    COURSENAME_DESC: 'course.name:desc',
    COUNTRATING: 'count_rating',
    GRADE: 'grade',
    GRADE_ASC: 'grade:asc',
    GRADE_DESC: 'grade:desc',
  },
};
