import { createSlice } from '@reduxjs/toolkit';
import { getUploadedOutlines } from './asyncAction';

const initialState = {
  outlines: null,
  isLoading: false,
  errorMessage: '',
};

const uploadedOutlinesSlice = createSlice({
  name: 'uploadedOutlines',
  initialState,
  reducers: {
    clearUploadedOutlines(state) {
      state.outlines = null;
      state.errorMessage = '';
      state.isLoading = false;
    },
  },
  extraReducers: {
    [getUploadedOutlines.pending]: state => {
      state.outlines = null;
      state.isLoading = true;
      state.errorMessage = '';
    },
    [getUploadedOutlines.fulfilled]: (state, { payload }) => {
      const { outlines, pageData } = payload;

      state.outlines = { outlines, pageData };
      state.isLoading = false;
    },
    [getUploadedOutlines.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.errorMessage = error.message;
    },
  },
});

export const { clearUploadedOutlines } = uploadedOutlinesSlice.actions;

export default uploadedOutlinesSlice.reducer;
