import { createSlice } from '@reduxjs/toolkit';
import { getPurchases } from './asyncAction';

const initialState = {
  purchases: [],
  purchasesTotalCount: 0,
  isLoading: false,
  errorMessage: '',
};

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    clearPurchases() {
      return initialState;
    },
  },
  extraReducers: {
    // Get purchases
    [getPurchases.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getPurchases.fulfilled]: (state, { payload }) => {
      state.purchases = payload.purchases;
      state.purchasesTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getPurchases.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export const { clearPurchases } = purchasesSlice.actions;

export default purchasesSlice.reducer;
