import { saveAs } from 'file-saver';

import Client from '@/lib/axios';

const axios = Client();

export const fetchOutlines = filters =>
  axios.get('/outlines', { params: filters });

export const fetchOutline = id => axios.get(`/outlines/${id}`);

export const createOutline = data =>
  axios.post(`/outlines`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const downloadOutline = async ({ userId, outlineId, purchaseId }) => {
  const res = await axios.get(`/outlines/${outlineId}/download`, {
    params: { userId, purchaseId },
    responseType: 'arraybuffer',
  });

  const doc = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  saveAs(doc, `Outline-${outlineId}.docx`);
};

export const createReview = ({ outlineId, userId, rating, title, content }) =>
  axios.post(`/outlines/${outlineId}/reviews`, {
    userId,
    rating,
    title,
    content,
  });

export const fetchReviews = ({ outlineId, page, pageSize }) =>
  axios.get(`/outlines/${outlineId}/reviews`, {
    params: { page, pageSize },
  });

export const fetchReviewsDetails = outlineId =>
  axios.get(`/outlines/${outlineId}/reviews/details`);
