import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchBalance } from '@/api/user';

export const getBalance = createAsyncThunk(
  'balance/getBalance',
  async ({ userId, type, page, pageSize }) => {
    const response = await fetchBalance({ userId, type, page, pageSize });

    return response.data;
  },
);
