import { createSlice } from '@reduxjs/toolkit';
import { getCourses, getSingleCourse } from './asyncAction';

const initialState = {
  courses: [],
  coursesTotalCount: 0,
  isLoading: false,
  errorMessage: '',
  courseSelected: null,
};

const courseSelectedSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearCourses() {
      return initialState;
    },
  },
  extraReducers: {
    // Get courses
    [getCourses.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getCourses.fulfilled]: (state, { payload }) => {
      state.courses = payload.courses;
      state.coursesTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getCourses.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get single course
    [getSingleCourse.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getSingleCourse.fulfilled]: (state, { payload }) => {
      state.courseSelected = payload;
      state.isLoading = false;
    },
    [getSingleCourse.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export const { clearCourses } = courseSelectedSlice.actions;

export default courseSelectedSlice.reducer;
