import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPurchases } from '@/api/purchases';

export const getPurchases = createAsyncThunk(
  'purchases/getPurchases',
  async (filters = null) => {
    const response = await fetchPurchases(filters);

    return response.data;
  },
);
