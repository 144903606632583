import axios from 'axios';
import getConfig from 'next/config';
import { getSession } from 'next-auth/client';

let axiosInstance;

// Here is the place to add interceptors or auth headers
const Client = () => {
  if (axiosInstance !== undefined) {
    return axiosInstance;
  }

  const { publicRuntimeConfig } = getConfig();

  axiosInstance = axios.create({
    baseURL: publicRuntimeConfig.API_URL,
  });

  axiosInstance.interceptors.request.use(async config => {
    const session = await getSession();

    config.headers.Authorization = `Bearer ${session?.user?.accessToken}`;

    return config;
  });

  return axiosInstance;
};

export default Client;
