export const getPaymentMethodsList = state => state.payment.paymentMethods;
export const getIsLoadingPaymentsMethods = state => state.payment.isLoading;
export const getPaymentMethodsErrors = state => state.payment.errorMessage;
// Payment method
export const getIsCreating = state => state.payment.isCreating;
export const getCreatingError = state => state.payment.creatingError;
export const getPaymentMethodCreated = state =>
  state.payment.paymentMethodCreated;

export const getSelectedPaymentMethod = state =>
  state.payment.selectedPaymentMethod;
export const getBankAccountsInfo = state => state.payment.bankAccounts;
export const getBankAccountCreated = state => state.payment.bankAccountCreated;
// Update bank account
export const getIsUpdatingBankAccount = state =>
  state.payment.isUpdatingBankAccount;
export const getUpdatingBankAccountError = state =>
  state.payment.updatingBankAccountError;
// Remove bank account
export const getIsRemovingBankAccount = state =>
  state.payment.isRemovingBankAccount;
export const getRemovingBankAccountError = state =>
  state.payment.removingBankAccountError;
// Buy outline
export const getOutlineBought = state => state.payment.outlineBought;
export const getIsBuying = state => state.payment.isBuying;
export const getBuyingError = state => state.payment.buyingError;
export const getPurchaseId = state => state.payment.purchaseId;
// detach payment method
export const getIsRemoving = state => state.payment.isRemoving;
