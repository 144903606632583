import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchUserPaymentMethods,
  attachPaymentMethodToCustomer,
  createPaymentIntent,
  createBankAccount,
  updateDefaultBankAccount,
  fetchBankAccount,
  detachPaymentMethodFromCustomer,
  removeBankAccount as removeAPIBankAccount,
} from '@/api/user';

import { fetchUser } from '@/stores/user';

export const getPaymentMethods = createAsyncThunk(
  'payment/getPaymentMethods',
  async ({ id }) => {
    const response = await fetchUserPaymentMethods(id);

    return response.data;
  },
);

export const attachPaymentMethod = createAsyncThunk(
  'payment/attachPaymentMethod',
  async (
    { userId, paymentMethod, defaultPaymentMethod },
    { dispatch, getState },
  ) => {
    await attachPaymentMethodToCustomer({
      userId,
      paymentMethod,
      defaultPaymentMethod,
    });

    const {
      user: { auth_id, stripe_customer_id },
    } = getState().user;

    if (!stripe_customer_id) {
      dispatch(fetchUser(auth_id));
    }

    if (defaultPaymentMethod) {
      return paymentMethod;
    }
  },
);

export const addBankAccount = createAsyncThunk(
  'payment/addBankAccount',
  async ({ userId, token }) => {
    const response = await createBankAccount({ userId, token });

    return response.data;
  },
);

export const setDefaultBankAccount = createAsyncThunk(
  'payment/updateDefaultBankAccount',
  async ({ userId, bankAccountId }) => {
    const response = await updateDefaultBankAccount({ userId, bankAccountId });

    return response.data;
  },
);

export const removeBankAccount = createAsyncThunk(
  'payment/removeBankAccount',
  async ({ userId, bankAccountId }) => {
    const response = await removeAPIBankAccount({ userId, bankAccountId });

    return response.data;
  },
);

export const getBankAccount = createAsyncThunk(
  'payment/getBankAccount',
  async ({ userId, limit }) => {
    const response = await fetchBankAccount({ userId, limit });

    return response.data;
  },
);

export const buyOutline = createAsyncThunk(
  'payment/buyOutline',
  async ({ userId, outlineId, paymentMethodId }) => {
    const response = await createPaymentIntent({
      userId,
      outlineId,
      paymentMethodId,
    });

    return response.data;
  },
);

export const detachPaymentMethod = createAsyncThunk(
  'payment/detachPaymentMethod',
  async ({ userId, paymentMethodId }) => {
    const response = await detachPaymentMethodFromCustomer({
      userId,
      paymentMethodId,
    });

    return response.data;
  },
);
