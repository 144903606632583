import { createSlice } from '@reduxjs/toolkit';
import { getProfessorsSuggestions } from './asyncAction';

const initialState = {
  professorsSuggestions: [],
  professorsSuggestionsTotalCount: 0,
  isLoading: false,
  errorMessage: '',
};

const professorSuggestionsSlice = createSlice({
  name: 'professorsSuggestions',
  initialState,
  reducers: {
    clearProfessorsSuggestions() {
      return initialState;
    },
  },
  extraReducers: {
    // Get professors suggestions
    [getProfessorsSuggestions.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getProfessorsSuggestions.fulfilled]: (state, { payload }) => {
      state.professorsSuggestions = payload.professorsSuggestions;
      state.professorsSuggestionsTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getProfessorsSuggestions.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export const { clearProfessorsSuggestions } = professorSuggestionsSlice.actions;

export default professorSuggestionsSlice.reducer;
