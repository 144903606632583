import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSchools, fetchSchool } from '@/api/schools';

export const getSchools = createAsyncThunk(
  'schools/getSchools',
  async (filters = null) => {
    const response = await fetchSchools(filters);

    return response.data;
  },
);

export const getSingleSchool = createAsyncThunk(
  'school/getSchool',
  async id => {
    const response = await fetchSchool(id);

    return response.data;
  },
);
