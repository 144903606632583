import { createSlice } from '@reduxjs/toolkit';
import { getProfessors, getSingleProfessor } from './asyncAction';

const initialState = {
  professors: [],
  professorsTotalCount: 0,
  isLoading: false,
  errorMessage: '',
  professorSelected: null,
};

const professorSelectedSlice = createSlice({
  name: 'professors',
  initialState,
  reducers: {
    clearProfessors() {
      return initialState;
    },
  },
  extraReducers: {
    // Get professors
    [getProfessors.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getProfessors.fulfilled]: (state, { payload }) => {
      state.professors = payload.professors;
      state.professorsTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getProfessors.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get single professor
    [getSingleProfessor.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getSingleProfessor.fulfilled]: (state, { payload }) => {
      state.professorSelected = payload;
      state.isLoading = false;
    },
    [getSingleProfessor.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export const { clearProfessors } = professorSelectedSlice.actions;

export default professorSelectedSlice.reducer;
