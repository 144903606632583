import { createSlice } from '@reduxjs/toolkit';
import {
  getFirstyearOutlines,
  getTopRatedOutlines,
  getRecentUplaodedOutlines,
  getCustomRecommendedOutlines,
} from './asyncAction';

const initialState = {
  firstyearOutlines: [],
  topRatedOutlines: [],
  recentUplaodedOutlines: [],
  courseOutlines: [],
  professorOutlines: [],

  isLoading: false,
  errorMessage: '',
};

const removeOutlineFromList = (outlines, id) =>
  outlines.filter(outline => outline.id !== id);

const recommendedOutlinesSlice = createSlice({
  name: 'recommendedOutlines',
  initialState,
  extraReducers: {
    // Get 1st year outlines
    [getFirstyearOutlines.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getFirstyearOutlines.fulfilled]: (state, { payload }) => {
      state.firstyearOutlines = payload;
      state.isLoading = false;
    },
    [getFirstyearOutlines.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get top rated outlines
    [getTopRatedOutlines.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getTopRatedOutlines.fulfilled]: (state, { payload }) => {
      state.topRatedOutlines = payload;
      state.isLoading = false;
    },
    [getTopRatedOutlines.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get recent uploaded outlines
    [getRecentUplaodedOutlines.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getRecentUplaodedOutlines.fulfilled]: (state, { payload }) => {
      state.recentUplaodedOutlines = payload;
      state.isLoading = false;
    },
    [getRecentUplaodedOutlines.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Get custom recommended outlines
    [getCustomRecommendedOutlines.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getCustomRecommendedOutlines.fulfilled]: (state, { payload }) => {
      const { courseOutlines, professorOutlines, outlineId } = payload;

      const filteredCourseOutlines = removeOutlineFromList(
        courseOutlines,
        outlineId,
      );
      const filteredProfessorOutlines = removeOutlineFromList(
        professorOutlines,
        outlineId,
      );

      state.courseOutlines = filteredCourseOutlines;
      state.professorOutlines = filteredProfessorOutlines;
      state.isLoading = false;
    },
    [getCustomRecommendedOutlines.rejected]: (
      state,
      { error: { message } },
    ) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export default recommendedOutlinesSlice.reducer;
