import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProfessors, fetchProfessor } from '@/api/professors';

export const getProfessors = createAsyncThunk(
  'professors/getProfessors',
  async (filters = null) => {
    const response = await fetchProfessors(filters);

    return response.data;
  },
);

export const getSingleProfessor = createAsyncThunk(
  'professor/getProfessor',
  async id => {
    const response = await fetchProfessor(id);

    return response.data;
  },
);
