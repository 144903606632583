import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  allOutlinesManagement,
  updateApprovalStatus,
  downloadOutline,
} from '@/api/admin';

export const getAllOutlinesManagement = createAsyncThunk(
  'outlinesManagement/outlines',
  async (query = null) => {
    const response = await allOutlinesManagement(query);

    return response.data;
  },
);

export const updateOutlineApprovalStatus = createAsyncThunk(
  'outlinesManagement/updateApprovalStatus',
  async id => {
    const response = await updateApprovalStatus(id);

    return response.data;
  },
);

export const getDownloadOutline = createAsyncThunk(
  'outlinesManagement/downloadOutline',
  async id => await downloadOutline(id),
);
