export const constants = {
  PREVIOUS_YEARS: 6,
  UPLOAD_FORM_ENTITIES: ['schools', 'professors', 'courses'],
  COURSE_PERIOD: {
    SPRING: 'Spring',
    FALL: 'Fall',
    WINTER: 'Winter',
    SUMMER: 'Summer',
  },
  COURSE_GRADE: {
    A_PLUS: 'A+',
    A: 'A',
    A_MINUS: 'A-',
    B_PLUS: 'B+',
    B: 'B',
    B_MINUS: 'B-',
    C_PLUS: 'C+',
    C: 'C',
    C_MINUS: 'C-',
    D_PLUS: 'D+',
    D: 'D',
    D_MINUS: 'D-',
    F: 'F',
  },
  OUTLINE_VALID_EXTENSIONS: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ],
  NESTED_LISTS: 2,
};
