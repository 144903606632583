import { saveAs } from 'file-saver';
import Client from '@/lib/axios';

const axios = Client();

export const allUsersManagement = filters =>
  axios.get('/admin/users', { params: filters });

export const allOutlinesManagement = query =>
  axios.get('/admin/outlines', { params: query });

export const updateApprovalStatus = id =>
  axios.patch(`/admin/outline-approval/${id}`);

export const updateUserActiveStatus = id =>
  axios.patch(`/admin/update-user-active/${id}`);

export const updateOutline = ({ id, outline }) =>
  axios.patch(`/admin/update-outline/${id}`, outline);

export const downloadOutline = async id => {
  const res = await axios.get(`/admin/download-outline/${id}`, {
    responseType: 'arraybuffer',
  });

  const doc = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  saveAs(doc, `Outline-${id}.docx`);
};
