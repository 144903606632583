import { createSlice } from '@reduxjs/toolkit';
import { getCoursesSuggestions } from './asyncAction';

const initialState = {
  coursesSuggestions: [],
  coursesSuggestionsTotalCount: 0,
  isLoading: false,
  errorMessage: '',
};

const courseSuggestionsSlice = createSlice({
  name: 'coursesSuggestions',
  initialState,
  reducers: {
    clearCoursesSuggestions() {
      return initialState;
    },
  },
  extraReducers: {
    // Get courses suggestions
    [getCoursesSuggestions.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getCoursesSuggestions.fulfilled]: (state, { payload }) => {
      state.coursesSuggestions = payload.coursesSuggestions;
      state.coursesSuggestionsTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getCoursesSuggestions.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
  },
});

export const { clearCoursesSuggestions } = courseSuggestionsSlice.actions;

export default courseSuggestionsSlice.reducer;
