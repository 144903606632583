import { createSlice } from '@reduxjs/toolkit';
import {
  getPaymentMethods,
  attachPaymentMethod,
  buyOutline,
  addBankAccount,
  getBankAccount,
  detachPaymentMethod,
  setDefaultBankAccount,
  removeBankAccount,
} from './asyncAction';

const initialState = {
  paymentMethods: null,
  selectedPaymentMethod: null,
  bankAccounts: [],
  isLoading: false,
  errorMessage: '',
  // Create payment method and bank account
  isCreating: false,
  creatingError: '',
  paymentMethodCreated: false,
  bankAccountCreated: false,
  // Update bank account
  isUpdatingBankAccount: false,
  updatingBankAccountError: '',
  // Remove bank account
  isRemovingBankAccount: false,
  removingBankAccountError: '',
  // Buy outline
  isBuying: false,
  buyingError: '',
  outlineBought: false,
  purchaseId: null,
  // Detach payment method
  isRemoving: false,
  detachError: '',
};

const paymentMethodsSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    selectPaymentMethod(state, { payload }) {
      state.selectedPaymentMethod = payload;
    },
    resetCreating(state) {
      state.paymentMethodCreated = false;
      state.bankAccountCreated = false;
    },
    clearPaymentMethods() {
      return initialState;
    },
    clearCreatingError(state) {
      state.creatingError = '';
    },
    clearBuying(state) {
      state.buyingError = '';
      state.outlineBought = false;
      state.purchaseId = null;
    },
  },
  extraReducers: {
    [getPaymentMethods.pending]: state => {
      state.paymentMethods = null;
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getPaymentMethods.fulfilled]: (state, { payload }) => {
      const { paymentMethods } = payload;

      if (paymentMethods.length) {
        const defaultPaymentMethod = paymentMethods.find(
          paymentMethod => paymentMethod.default === true,
        );
        const selectedMethodExists = paymentMethods.some(
          paymentMethod => paymentMethod.id === state.selectedPaymentMethod,
        );

        !selectedMethodExists &&
          (defaultPaymentMethod
            ? (state.selectedPaymentMethod = defaultPaymentMethod.id)
            : (state.selectedPaymentMethod =
                paymentMethods[paymentMethods.length - 1].id));

        state.paymentMethods = payload.paymentMethods;
      } else {
        state.selectedPaymentMethod = null;
        state.paymentMethods = null;
      }
      state.isLoading = false;
    },
    [getPaymentMethods.rejected]: (state, { error }) => {
      state.errorMessage = error.message;
      state.isLoading = false;
    },
    // Attach payment method to customer
    [attachPaymentMethod.pending]: state => {
      state.creatingError = '';
      state.isCreating = true;
      state.paymentMethodCreated = false;
    },
    [attachPaymentMethod.fulfilled]: (state, { payload }) => {
      state.isCreating = false;
      state.creatingError = '';
      state.paymentMethodCreated = true;
      if (payload) state.selectedPaymentMethod = payload;
    },
    [attachPaymentMethod.rejected]: (state, { error }) => {
      state.creatingError = error.message;
      state.isCreating = false;
      state.paymentMethodCreated = false;
    },
    // Get bank account
    [getBankAccount.pending]: state => {
      state.isLoading = true;
      state.errorMessage = '';
    },
    [getBankAccount.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.errorMessage = '';
      if (payload?.bank_accounts.length)
        state.bankAccounts = payload.bank_accounts;
    },
    [getBankAccount.rejected]: (state, { error }) => {
      state.errorMessage = error.message;
      state.isLoading = false;
    },
    // Add bank account
    [addBankAccount.pending]: state => {
      state.isCreating = true;
      state.creatingError = '';
    },
    [addBankAccount.fulfilled]: (state, { payload }) => {
      state.isCreating = false;
      state.creatingError = '';
      state.bankAccountCreated = true;
      state.bankAccounts = state.bankAccounts.concat(payload);
    },
    [addBankAccount.rejected]: (state, { error }) => {
      state.creatingError = error.message;
      state.isCreating = false;
    },
    // Update default bank account
    [setDefaultBankAccount.pending]: state => {
      state.isUpdatingBankAccount = true;
      state.updatingBankAccountError = '';
    },
    [setDefaultBankAccount.fulfilled]: (state, { payload }) => {
      state.isUpdatingBankAccount = false;
      state.updatingBankAccountError = '';
      state.bankAccounts = payload.bank_accounts;
    },
    [setDefaultBankAccount.rejected]: (state, { error }) => {
      state.isUpdatingBankAccount = false;
      state.updatingBankAccountError = error.message;
    },
    // Remove bank account
    [removeBankAccount.pending]: state => {
      state.isRemovingBankAccount = true;
      state.removingBankAccountError = '';
    },
    [removeBankAccount.fulfilled]: (state, { payload }) => {
      state.isRemovingBankAccount = false;
      state.removingBankAccountError = '';
      state.bankAccounts = payload.bank_accounts;
    },
    [removeBankAccount.rejected]: (state, { error }) => {
      state.isRemovingBankAccount = false;
      state.removingBankAccountError = error.message;
    },
    // Buy outline
    [buyOutline.pending]: state => {
      state.isBuying = true;
      state.buyingError = '';
      state.outlineBought = false;
      state.purchaseId = null;
    },
    [buyOutline.fulfilled]: (state, { payload }) => {
      const { id } = payload;

      state.isBuying = false;
      state.buyingError = '';
      state.outlineBought = true;
      state.purchaseId = id;
    },
    [buyOutline.rejected]: (state, { error }) => {
      state.isBuying = false;
      state.buyingError = error.message;
      state.outlineBought = false;
      state.purchaseId = null;
    },
    // detach payment method from customer
    [detachPaymentMethod.pending]: state => {
      state.isRemoving = true;
    },
    [detachPaymentMethod.fulfilled]: (state, { payload }) => {
      const { id } = payload;

      state.paymentMethods = state.paymentMethods.filter(
        paymentMethod => paymentMethod.id !== id,
      );
      if (!state.paymentMethods.length) state.selectedPaymentMethod = null;
      state.isRemoving = false;
    },
    [detachPaymentMethod.rejected]: (state, { error }) => {
      state.isRemoving = false;
      state.detachError = error.message;
    },
  },
});

export const {
  clearPaymentMethods,
  selectPaymentMethod,
  resetCreating,
  clearCreatingError,
  clearBuying,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
