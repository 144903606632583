import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchUploadedOutlines } from '@/api/user';

export const getUploadedOutlines = createAsyncThunk(
  'uploadedOutlines/getUploadedOutlines',
  async ({ userId, page, pageSize }) => {
    const response = await fetchUploadedOutlines({
      userId,
      page,
      pageSize,
    });

    return response.data;
  },
);
