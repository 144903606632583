import { createSlice } from '@reduxjs/toolkit';
import { getPurchasedOutlines } from './asyncAction';

const initialState = {
  outlines: null,
  isLoading: false,
  errorMessage: '',
};

const purchasedOutlinesSlice = createSlice({
  name: 'purchasedOutlines',
  initialState,
  reducers: {
    clearPurchasedOutlines(state) {
      state.outlines = null;
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [getPurchasedOutlines.pending]: state => {
      state.outlines = null;
      state.isLoading = true;
      state.errorMessage = '';
    },
    [getPurchasedOutlines.fulfilled]: (state, { payload }) => {
      const { buyersOutlines, pageData } = payload;

      state.outlines = { buyersOutlines, pageData };
      state.isLoading = false;
    },
    [getPurchasedOutlines.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.errorMessage = error.message;
    },
  },
});

export const { clearPurchasedOutlines } = purchasedOutlinesSlice.actions;

export default purchasedOutlinesSlice.reducer;
