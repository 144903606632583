import { createSlice } from '@reduxjs/toolkit';
import { getPurchasedOutlineByUser } from './asyncAction';

const initialState = {
  outline: null,
  isLoading: false,
  errorMessage: '',
};

const purchasedOutlineByUserSlice = createSlice({
  name: 'purchasedOutlineByUser',
  initialState,
  reducers: {
    clearPurchasedOutlineByUser(state) {
      state.outline = null;
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [getPurchasedOutlineByUser.pending]: state => {
      state.outline = null;
      state.isLoading = true;
      state.errorMessage = '';
    },
    [getPurchasedOutlineByUser.fulfilled]: (state, { payload }) => {
      if (payload.message) {
        state.errorMessage = payload.message;
      } else {
        delete payload.message;
        state.outline = payload;
      }
      state.isLoading = false;
    },
    [getPurchasedOutlineByUser.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.errorMessage = error.message;
    },
  },
});

export const { clearPurchasedOutlineByUser } =
  purchasedOutlineByUserSlice.actions;

export default purchasedOutlineByUserSlice.reducer;
