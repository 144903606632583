import Client from '@/lib/axios';

const axios = Client();

export const fetchSchools = filters =>
  axios.get('/schools/all', { params: filters });

export const fetchSchool = id => axios.get(`/schools/${id}`);

export const createSchool = ({ name, abbreviation }) =>
  axios.post(`/schools`, { name, abbreviation });

export const updateSchool = ({ schoolId, name, abbreviation }) =>
  axios.put(`/schools/${schoolId}`, { name, abbreviation });

export const deleteSchool = schoolId => axios.delete(`/schools/${schoolId}`);
