module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  localeDetection: false,
  pages: {
    '*': [
      'common',
      'searchbar',
      'payment',
      'sideMenu',
      'balance',
      'purchasedOutlines',
      'makeReview',
      'uploadedOutlines',
      'outlines',
      'carousel',
      'support',
      'faq',
      'admin',
      'makeSuggestion',
    ],
    '/welcome': ['welcome'],
    '/outlines/upload/[id]': ['successfulUpload'],
  },
};
