import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchPurchasedOutlineByUser } from '@/api/purchasedOutlineByUser';

export const getPurchasedOutlineByUser = createAsyncThunk(
  'purchasedOutlineByUser/getPurchasedOutlineByUser',
  async ({ userId, outlineId }) => {
    const response = await fetchPurchasedOutlineByUser({
      userId,
      outlineId,
    });

    return response.data;
  },
);
