import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProfessorsSuggestions } from '@/api/professorsSuggestions';

export const getProfessorsSuggestions = createAsyncThunk(
  'professorsSuggestions/getProfessorsSuggestions',
  async (filters = null) => {
    const response = await fetchProfessorsSuggestions(filters);

    return response.data;
  },
);
