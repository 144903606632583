import { createSlice } from '@reduxjs/toolkit';
import { getAllUsersManagement, fetchUser, createNewUser } from './asyncAction';

const initialState = {
  users: [],
  usersTotalCount: 0,

  user: null,
  isLoading: false,
  errorMessage: '',

  shouldCreateUser: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser() {
      return initialState;
    },
  },
  extraReducers: {
    // Get all users management
    [getAllUsersManagement.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getAllUsersManagement.fulfilled]: (state, { payload }) => {
      state.users = payload.usersManagement;
      state.usersTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getAllUsersManagement.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Fetch single user
    [fetchUser.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
      state.shouldCreateUser = false;
    },
    [fetchUser.rejected]: (state, { error, payload }) => {
      if (payload?.status === 404) state.shouldCreateUser = true;
      state.errorMessage = error.message;
      state.isLoading = false;
    },
    // Create User
    [createNewUser.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [createNewUser.fulfilled]: state => {
      state.isLoading = false;
      state.errorMessage = '';
      state.shouldCreateUser = false;
    },
    [createNewUser.rejected]: (state, { error }) => {
      state.errorMessage = error.message;
      state.isLoading = false;
      state.shouldCreateUser = true;
    },
  },
});

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;
