export const getAllOutlinesManagementList = state =>
  state.outlinesManagement.outlines;

export const getOutlinesManagementTotalCount = state =>
  state.outlinesManagement.outlinesTotalCount;

export const getIsLoadingOutlinesManagement = state =>
  state.outlinesManagement.isLoading;

export const getErrorOutlinesManagement = state =>
  state.adminOutlines.errorMessage;
