import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCourses, fetchCourse } from '@/api/courses';

export const getCourses = createAsyncThunk(
  'courses/getCourses',
  async (filters = null) => {
    const response = await fetchCourses(filters);

    return response.data;
  },
);

export const getSingleCourse = createAsyncThunk(
  'course/getCourse',
  async id => {
    const response = await fetchCourse(id);

    return response.data;
  },
);
