import { createSlice } from '@reduxjs/toolkit';
import {
  getAllOutlinesManagement,
  updateOutlineApprovalStatus,
} from './asyncAction';

const initialState = {
  outlines: [],
  outlinesTotalCount: 0,

  isLoading: false,
  errorMessage: '',
};

const outlinesManagementSlice = createSlice({
  name: 'outlinesManagement',
  initialState,
  reducers: {
    clearOutlinesManagement() {
      return initialState;
    },
  },
  extraReducers: {
    // Get all outlinesManagement
    [getAllOutlinesManagement.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [getAllOutlinesManagement.fulfilled]: (state, { payload }) => {
      state.outlines = payload.outlinesManagement;
      state.outlinesTotalCount = payload.pageData?.total;
      state.isLoading = false;
    },
    [getAllOutlinesManagement.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
      state.isLoading = false;
    },
    // Update approval status from Outline
    [updateOutlineApprovalStatus.fulfilled]: (state, { payload }) => {
      const { id, approval_status } = payload;
      const updatedOutlines = state.outlines.map(outline => {
        if (id === outline.id) {
          return { ...outline, approval_status };
        }

        return {
          ...outline,
        };
      });

      state.outlines = updatedOutlines;
    },
    [updateOutlineApprovalStatus.rejected]: (state, { error: { message } }) => {
      state.errorMessage = message;
    },
  },
});

export const { clearOutlinesManagement } = outlinesManagementSlice.actions;

export default outlinesManagementSlice.reducer;
