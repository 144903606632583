
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';
import getConfig from 'next/config';
import { Provider as AuthProvider } from 'next-auth/client';
import { useStore } from '@/stores/store';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import 'draft-js/dist/Draft.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import '@/components/Mailmunch/MailmunchLink.css';

export const REFRESH_SESSION_IN_SECONDS = 15 * 60; // Refresh session every 15 minutes

const { publicRuntimeConfig } = getConfig();

const stripePromise = loadStripe(publicRuntimeConfig.STRIPE_PUBLIC_KEY);

const RemyOutlines = ({ Component, pageProps }) => {
  const store = useStore(pageProps.initialReduxState);

  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });

  return (
    <Elements stripe={stripePromise}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider
            session={pageProps.session}
            clientMaxAge={REFRESH_SESSION_IN_SECONDS}
            keepAlive
          >
            <Component {...pageProps} />
          </AuthProvider>
        </PersistGate>
      </ReduxProvider>
    </Elements>
  );
};

RemyOutlines.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

RemyOutlines.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  pageProps: PropTypes.shape({
    session: PropTypes.shape({}),
    initialReduxState: PropTypes.shape({}),
  }),
};

const __Page_Next_Translate__ = RemyOutlines;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  