import Client from '@/lib/axios';
import { constants } from '../constants';

const axios = Client();

export const fetchByName = (name, limit) => axios.get(`/search`, { params: { name, limit } });

export const fetchByEntity = (name, entity, limit) => {
  if (constants.UPLOAD_FORM_ENTITIES.includes(entity)) {
    return axios.get(`/${entity}`, { params: { name, limit } });
  }
  throw new Error('Invalid entity');
};
