export const getOutlinesEntities = state => state.outlines.outlines;
export const getOutlinesTotalCount = state => state.outlines.outlinesTotalCount;
export const getOutlineSelected = state => state.outlines.outlineSelected;
export const getIsLoadingOutlines = state => state.outlines.isLoading;
export const getOutlinesErrors = state => state.outlines.errorMessage;
// Download outline
export const getIsLoadingDownload = state => state.outlines.isLoadingDownload;
export const getDownloaded = state => state.outlines.downloaded;
export const getDownloadError = state => state.outlines.downloadError;
export const getRefreshTab = state => state.outlines.refreshTab;
// Create review
export const getOutlineReview = state => state.outlines.outlineReview;
export const getIsLoadingReview = state => state.outlines.isLoadingReview;
export const getReviewErrors = state => state.outlines.reviewError;

export const getOutlineReviews = state => state.outlines.reviews;
export const getOutlineReviewsDetails = state => state.outlines.reviewsDetails;
