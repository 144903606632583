import Client from '@/lib/axios';

const axios = Client();

export const fetchCoursesSuggestions = filters =>
  axios.get('/coursesSuggestions/all-suggestions', { params: filters });

export const createCourseSuggestion = ({ name, user_id }) =>
  axios.post(`/coursesSuggestions`, { name, user_id });

export const approveCourseSuggestion = courseSuggestionId =>
  axios.post(`/coursesSuggestions/approve/${courseSuggestionId}`);

export const deleteCourseSuggestion = courseSuggestionId =>
  axios.delete(`/coursesSuggestions/${courseSuggestionId}`);
