import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchPurchasedOutlines } from '@/api/user';

export const getPurchasedOutlines = createAsyncThunk(
  'purchasedOutlines/getPurchasedOutlines',
  async ({ userId, page, pageSize }) => {
    const response = await fetchPurchasedOutlines({
      userId,
      page,
      pageSize,
    });

    return response.data;
  },
);
