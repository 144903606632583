export const getFirstyearOutlinesList = state =>
  state.recommendedOutlines.firstyearOutlines;
export const getTopRatedOutlinesList = state =>
  state.recommendedOutlines.topRatedOutlines;
export const getRecentUplaodedOutlinesList = state =>
  state.recommendedOutlines.recentUplaodedOutlines;
export const getCourseOutlinesList = state =>
  state.recommendedOutlines.courseOutlines;
export const getProfessorOutlinesList = state =>
  state.recommendedOutlines.professorOutlines;

export const getIsLoading = state => state.recommendedOutlines.isLoading;
export const getErrorLoading = state => state.recommendedOutlines.errorMessage;
