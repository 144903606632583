import { createSlice } from '@reduxjs/toolkit';
import { fetchByName } from './asyncAction';

const initialState = {
  selectedOption: null,
  options: null,
  isLoading: false,
  errorMessage: '',
};

const searchbarSlice = createSlice({
  name: 'searchbar',
  initialState,
  reducers: {
    setSelectedOption(state, { payload }) {
      state.selectedOption = payload;
    },
    clearSearch(state) {
      state.options = null;
      state.selectedOption = null;
    },
  },
  extraReducers: {
    [fetchByName.pending]: state => {
      state.errorMessage = '';
      state.isLoading = true;
    },
    [fetchByName.fulfilled]: (state, { payload }) => {
      const options = Object.keys(payload).filter(
        category => payload[category].length,
      );

      if (options.length) {
        state.options = payload;
      } else {
        state.options = null;
      }

      state.isLoading = false;
    },
    [fetchByName.rejected]: (state, { error }) => {
      state.errorMessage = error.message;
      state.isLoading = false;
      state.options = null;
    },
  },
});

export const { setSelectedOption, clearSearch } = searchbarSlice.actions;

export default searchbarSlice.reducer;
